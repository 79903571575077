<template>
  <div class="imageDetail">
    <!-- <div class="topHead clearfix">
        <div class="fl title">{{  }}</div>
       <div class="fr return">
          <span class="textLink" @click="$router.back(-1)">
            <i class="el-icon-arrow-left"></i>
            {{ $t("back") }}
          </span>
        </div> 
      </div>-->
    <div class="detail onlyBorderBottomForm">
      <el-form
        :label-position="'left'"
        label-width="130px"
        :model="from"
        ref="ruleForm"
        :rules="rules"
        :hide-required-asterisk="true"
      >
        <el-form-item :label="$t('image') + $t('colon')">
          <el-image
            style="width: 350px; height: 190px; background: #f2f2f2"
            v-if="detail.url"
            :src="detail.url"
            :preview-src-list="[detail.url]"
            :fit="'contain'"
          ></el-image>
        </el-form-item>
        <el-form-item :label="$t('designation') + $t('colon')" prop="name">
          <span v-if="isExamine">{{ detail.name }}</span>
          <el-input
            v-else
            style="width: 300px"
            v-model="from.name"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('size') + $t('colon')">{{
          detail.size
        }}</el-form-item>
        <el-form-item :label="$t('format') + $t('colon')">{{
          detail.format
        }}</el-form-item>
        <el-form-item :label="$t('resolvingPower') + $t('colon')"
          >{{ detail.width }}*{{ detail.height }}</el-form-item
        >
        <el-form-item :label="$t('createTime') + $t('colon')">{{
          detail.created_time | secondFormat
        }}</el-form-item>
        <!-- <el-form-item :label="$t('changeTime')">2020-02-12 21:00:00</el-form-item> -->
      </el-form>
    </div>
    <div class="modifyBtn">
      <el-button type="primary" size="small" @click="modify" v-if="isExamine&&inspectButton('coi:img:mdi')" >{{
        $t("modify")
      }}</el-button>
      <el-button
        type="primary"
        size="small"
        @click="saveInfo"
        :loading="saveLoading"
        v-if="!isExamine"
        >{{ $t("save") }}</el-button
      >
      <el-button
        type="default"
        size="small"
        @click="cancelModify"
        v-if="!isExamine"
        >{{ $t("cancel") }}</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    detail() {
      this.from.name = this.detail.name;
      this.isExamine = true;
      return this.detail;
    },
  },
  data() {
    return {
      isExamine: true,
      saveLoading: false,
      from: {
        name: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: " ",
            trigger: "blur",
          },
          {
            min: 3,
            max: 50,
            message: this.$t("pictureSetTipslength"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    modify() {
      this.from.name = this.detail.name;
      this.isExamine = false;
    },
    cancelModify() {
      this.isExamine = true;
      this.from.name = "";
      this.$refs["ruleForm"].clearValidate();
    },
    saveInfo() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          this.saveLoading = true;
          try {
            let params = {
              picture_id: this.detail.id,
              name: this.from.name,
            };
            let data = await this.$store.dispatch(
              "baseConsole/company_changePicture",
              params
            );
            if (data.success) {
              this.detail.name = this.from.name;
              this.saveLoading = false;
              this.isExamine = true;
              this.$emit("successChange");
              this.$ssTip();
            }
          } catch {
            this.saveLoading = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.imageDetail {
  padding: 20px;
  .title {
    line-height: 42px;
    font-size: 14px;
    font-weight: 900;
  }
  .textLink {
    float: right;
    line-height: 42px;
    font-size: 12px;
    color: #858585;
    cursor: pointer;
  }
  .detail {
    margin-top: 20px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .modifyBtn {
    padding-top: 10px;
    position: absolute;
    bottom: 20px;
  }
}
</style>
<template>
  <div class="baseConsoleTablePage hasTopButton">
    <div class="pageTopButton">
      <el-button
        v-if="inspectButton('coi:img:upi')"
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="uploadDialog = true"
        >{{ $t("uploadImg") }}</el-button
      >
    </div>
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="100"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column prop="date" :label="$t('image')" min-width="80px">
          <template slot-scope="scope">
            <el-image
              :fit="'contain'"
              style="width: 100px; height: 68px; background: #f2f2f2"
              :src="scope.row.url"
              :preview-src-list="[scope.row.url]"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('imageName')" min-width="120px">
          <template slot-scope="scope">
            <div>
              <span
                class="cursor rowName"
                :title="scope.row.name"
                @click="gotoCompanySet(scope.row)"
                >{{ scope.row.name }}</span
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="address"
          :label="$t('uploadTime')"
          min-width="130px"
        >
          <template slot-scope="scope">
            <div>
              <span style="font-size: 12px">{{
                scope.row.created_time | secondFormat
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" :label="$t('status')" min-width="90px">
          <template slot-scope="scope">
            <div v-if="scope.row.audit_status == 1">
              <span class="line lineBack1"></span>
              <span style="line-height: 23px; font-size: 12px">{{
                $t("normal")
              }}</span>
            </div>
            <div v-if="scope.row.audit_status == 0">
              <span class="line lineBack"></span>
              <span style="line-height: 23px; font-size: 12px">{{
                $t("inReview")
              }}</span>
            </div>
            <div v-if="scope.row.audit_status == 2">
              <span class="line lineBack2"></span>
              <span style="line-height: 23px; font-size: 12px">{{
                $t("aduitReject")
              }}</span>

              <el-popover
                placement="top-start"
                :title="LOCALE == 'en' ? 'Failure cause' : '失败原因'"
                width="200"
                trigger="click"
                :content="scope.row.reason"
              >
                <i
                  slot="reference"
                  class="el-icon-question"
                  style="color: #e6a23c; cursor: pointer"
                ></i>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" :label="$t('handle')" min-width="50px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="inspectButton('coi:img:del')"
              @click="deletePicture(scope.row.id)"
              >{{ $t("delete") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="$t('uploadImg')"
      :visible.sync="uploadDialog"
      width="670px"
      :destroy-on-close="true"
    >
      <uploadImg
        @closeDialog="closeDialog"
        :options="{
          company_id: USER_INFO.company_id,
          type: 'vividImg',
        }"
        @uploadSuccess="uploadSuccess"
      ></uploadImg>
      <!-- $store.state.userInfo.company_id -->
    </el-dialog>
    <!-- 详情 -->
    <el-drawer
      :title="$t('Basicconfiguration')"
      :visible.sync="drawerShow"
      direction="rtl"
      size="550px"
    >
      <div>
        <imageDetail
          :detail="detailObj"
          @successChange="drawerShow = false"
        ></imageDetail>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import uploadImg from "~/baseComponents/uploadListDialog";
import imageDetail from "~bac/components/company/picture";
export default {
  components: { uploadImg, imageDetail },
  data() {
    return {
      tableData: [],
      loading: false,
      total: 0,
      drawerSize: 1000,
      currentPage: 1,
      uploadDialog: false,
      drawerShow: false,
      detailObj: {},
    };
  },
  methods: {
    async getList() {
      this.loading = true;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_getCompanyPicture",
          {
            limit: 10,
            start: this.currentPage - 1,
            company_id: this.USER_INFO.company_id,
            picture_type: 1,
          }
        );
        if (result.success) {
          this.tableData = result.data;
          this.total = result.total;
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    uploadSuccess() {
      this.uploadDialog = false;
      this.getList();
    },
    closeDialog() {
      this.uploadDialog = false;
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    deletePicture(id) {
      this.$confirm(this.$t("confirmDelete"), this.$t("delete"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        closeOnClickModal: false,
        type: "warning",
      })
        .then(async () => {
          try {
            let params = {
              picture_id_list: id,
              company_id: this.USER_INFO.company_id,
            };
            let data = await this.$store.dispatch(
              "baseConsole/company_deletePicture",
              params
            );

            if (data.success) {
              this.$delTip();
              this.getList();
            }
          } catch (e) {}
        })
        .catch(() => {});
    },
    gotoCompanySet(val) {
      this.drawerShow = true;
      this.detailObj = val;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped lang="less">
.rowName {
  color: #0083f6;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.line {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
}
.lineBack {
  background: #409eff;
}
.lineBack1 {
  background: #52c41a;
}
.lineBack2 {
  background: #e6a23c;
}
/deep/.el-table td,
.el-table th {
  padding: 7px 0 5px 0;
}
</style>